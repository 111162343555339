/** @fileoverview Reset password template Component */
import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import { TiTickOutline } from "react-icons/ti";

import { handleEnterKeyDown } from "../utils";

import BackgroundTemplate from "./BackgroundTemplate";

/**
 * Renders the reset password template within a background video container.
 *
 * This component displays a form for users to set a new password and confirm it.
 * It utilizes the `BackgroundTemplate` component to provide a background video with a delayed content reveal.
 * The component also displays a password strength meter and visual indicators for password complexity requirements.
 * @param {object} props The component props.
 * @param {Function} props.handleSubmit A function to handle the form submission for password reset.
 * @param {string} props.password The user's new password (state variable).
 * @param {Function} props.setPassword A function to update the user's new password state.
 * @param {string} props.confirmPassword The user's confirm password (state variable).
 * @param {Function} props.setConfirmPassword A function to update the user's confirm password state.
 * @param {boolean} props.confirmPassowordMatch A boolean indicating if passwords match.
 * @param {boolean} props.isPasswordValid A boolean indicating if the password meets complexity requirements.
 * @param {boolean} props.passwordLength A boolean indicating if the password length is sufficient.
 * @param {boolean} props.capitalChars A boolean indicating if the password contains capital letters.
 * @param {boolean} props.smallChars A boolean indicating if the password contains lowercase letters.
 * @param {boolean} props.numberChars A boolean indicating if the password contains numbers.
 * @param {boolean} props.specialChars A boolean indicating if the password contains special characters.
 * @return {React.JSX} The rendered reset password template component.
 */
function ResetPasswordTemplate({
  handleSubmit,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  confirmPassowordMatch,
  isPasswordValid,
  passwordLength,
  capitalChars,
  smallChars,
  numberChars,
  specialChars,
}) {
  const PasswordStrengthBar = React.lazy(() => import("react-password-strength-bar"));

  return (
    <BackgroundTemplate>
      <Card.Header className="display-simple-flex-center login-header-color border-0 fw-bolder fs-3">
        Reset Password
      </Card.Header>

      <Card.Body>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text className="label-box justify-content-center">New Password</InputGroup.Text>
                <Form.Control
                  isValid={isPasswordValid}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder="New Password"
                  required
                  type="password"
                  value={password}
                />
                <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Looks Bad</Form.Control.Feedback>
              </InputGroup>
              <Suspense fallback={<div>Loading...</div>}>
                <PasswordStrengthBar password={password} />
              </Suspense>
            </Col>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <Table className="w-50" size="sm">
                <tbody>
                  <tr>
                    <th>Capital Chars</th>
                    <td>
                      {capitalChars ? (
                        <TiTickOutline style={{ color: "green" }} />
                      ) : (
                        <RxCrossCircled style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Small Chars</th>
                    <td>
                      {smallChars ? (
                        <TiTickOutline style={{ color: "green" }} />
                      ) : (
                        <RxCrossCircled style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Special Chars</th>
                    <td>
                      {specialChars ? (
                        <TiTickOutline style={{ color: "green" }} />
                      ) : (
                        <RxCrossCircled style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Numbers</th>
                    <td>
                      {numberChars ? (
                        <TiTickOutline style={{ color: "green" }} />
                      ) : (
                        <RxCrossCircled style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Length</th>
                    <td>
                      {passwordLength ? (
                        <TiTickOutline style={{ color: "green" }} />
                      ) : (
                        <RxCrossCircled style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Validation</th>
                    <td>
                      {isPasswordValid ? (
                        <TiTickOutline style={{ color: "green" }} />
                      ) : (
                        <RxCrossCircled style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text className="label-box justify-content-center">Confirm Password</InputGroup.Text>
                <Form.Control
                  isValid={confirmPassowordMatch}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder="Confirm New Password"
                  required
                  type="password"
                  value={confirmPassword}
                />
                <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Password didn&apos;t match with New password
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col className="display-simple-flex-center p-1" lg={12} md={12} xs={12} xxl={12}>
              <Button
                className="login-button me-2 mb-1"
                onClick={handleSubmit}
                type="submit"
                variant="success"
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </BackgroundTemplate>
  );
}

ResetPasswordTemplate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  confirmPassowordMatch: PropTypes.bool.isRequired,
  isPasswordValid: PropTypes.bool.isRequired,
  passwordLength: PropTypes.bool.isRequired,
  capitalChars: PropTypes.bool.isRequired,
  smallChars: PropTypes.bool.isRequired,
  numberChars: PropTypes.bool.isRequired,
  specialChars: PropTypes.bool.isRequired,
};

export default ResetPasswordTemplate;
