/** @fileoverview Password reset page */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ResetPasswordTemplate from "./Templates/ResetPasswordTemplate";
import { validateNewPasswordRequirements, handleResetPasswordSubmit } from "./utils";

/**
 *  Password reset page
 *  @return {React.JSX} Password reset page
 */
function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const orgName = searchParams.get("orgname");
  const [passwordLength, setPasswordLength] = useState(false);
  const [capitalChars, setCapitalChars] = useState(false);
  const [smallChars, setSmallChars] = useState(false);
  const [numberChars, setNumberChars] = useState(false);
  const [specialChars, setSpecialChars] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmPassowordMatch, setConfirmPassowordMatch] = useState(false);

  useEffect(() => {
    const isNewPasswordValid = validateNewPasswordRequirements(
      password,
      setPasswordLength,
      setCapitalChars,
      setSmallChars,
      setNumberChars,
      setSpecialChars
    );

    if (password === "" || !isNewPasswordValid) {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }

    if (password !== "" || confirmPassword !== "") {
      setConfirmPassowordMatch(password === confirmPassword);
    }
  }, [password, confirmPassword]);

  return (
    <ResetPasswordTemplate
      capitalChars={capitalChars}
      confirmPassowordMatch={confirmPassowordMatch}
      confirmPassword={confirmPassword}
      handleSubmit={(e) =>
        handleResetPasswordSubmit(e, {
          password,
          confirmPassword,
          token,
          orgName,
          setPassword,
          setConfirmPassword,
          navigate,
          setConfirmPassowordMatch,
        })
      }
      isPasswordValid={isPasswordValid}
      numberChars={numberChars}
      password={password}
      passwordLength={passwordLength}
      setConfirmPassword={setConfirmPassword}
      setPassword={setPassword}
      smallChars={smallChars}
      specialChars={specialChars}
    />
  );
}

export default ResetPassword;
