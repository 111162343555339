/** @fileoverview Login page component */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import LoginTemplate from "./Templates/LoginTemplate";
import cQINotify from "./Templates/cQINotify";
import { handleLoginSubmit } from "./utils";

/**
 * Login page component
 * @return {React.JSX} Login page component
 */
function Auth() {
  const [orgname, setOrgname] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const deviceId = uuidv4();
  const stateMessage = location.state?.message || "";

  useEffect(() => {
    if (stateMessage !== "") {
      cQINotify("Your password has been reset successfully.", "success");
      cQINotify("Login with your updated password", "info");
    }
  }, []);

  return (
    <LoginTemplate
      employeeNumber={employeeNumber}
      handleSubmit={(e) =>
        handleLoginSubmit(e, {
          orgname,
          employeeNumber,
          password,
          deviceId,
          setOrgname,
          setEmployeeNumber,
          setPassword,
        })
      }
      orgname={orgname}
      password={password}
      setEmployeeNumber={setEmployeeNumber}
      setOrgname={setOrgname}
      setPassword={setPassword}
    />
  );
}

export default Auth;
