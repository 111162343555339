/** @fileoverview background video template Component */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactGA from "react-ga4";

import vb from "../../media/background.mp4";
import cendiantQiLogo from "../../media/cendiant-qi-logo.png";
import musashiAiLogo from "../../media/logo2.png";

/**
 * Renders a background video template with a delayed content reveal.
 *
 * This component displays a background video with an overlay and a container for child content. After a specified
 * timeout (`TIMEOUT`), the child content fades in.
 * @param {object} props The component props.
 * @param {React.JSX} props.children The child component or JSX to be rendered inside the container.
 * @return {React.JSX} The rendered background video template component.
 */
function BackgroundTemplate({ children }) {
  const [visible, setVisible] = useState(false);
  const TIMEOUT = 500;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/token", title: "Login Attempt View" });
    const timer = setTimeout(() => setVisible(true), TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Card className="video-background border-0">
      <video autoPlay loop muted>
        <source src={vb} type="video/mp4" />
      </video>
      <Card className="overlay border-0" />
      <Card className="component border-0">
        <Card className="water-effect-container">
          {visible ? (
            <Card className="water-effect-item">
              <Card.Body className="Auth-form-container p-0">
                <Card className="Auth-form">
                  <Card.Body className="p-0">
                    <Card.Header className="display-simple-flex-center login-header-color border-0 fw-bolder fs-3">
                      <img alt="" src={musashiAiLogo} style={{ width: "40%" }} />
                    </Card.Header>
                    <Card.Header className="display-simple-flex-center login-header-color border-0 fw-bolder fs-3">
                      <img alt="" src={cendiantQiLogo} style={{ width: "100%" }} />
                    </Card.Header>
                    {children}
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          ) : null}
        </Card>
      </Card>
    </Card>
  );
}

BackgroundTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackgroundTemplate;
