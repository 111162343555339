/** @fileoverview App component */
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";
import Auth from "./components/Auth";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import configData from "./components/config";
import "react-toastify/dist/ReactToastify.css";

/**
 * App component
 * @return {React.JSX} App component
 */
function App() {
  ReactGA.initialize(configData.GOOGLE_ANALYTICS.MEASUREMENT_ID, { testMode: configData.GOOGLE_ANALYTICS.TESTMODE });

  const [token, setToken] = useState(localStorage.getItem("token"));

  return (
    <>
      <BrowserRouter>
        <div className="g-0 row">
          <Routes>
            <Route element={<Navigate to={configData.REDIRECT_URL.PATH} />} exact path="/" />
            <Route element={<Auth setToken={setToken} token={token} />} exact path={configData.REDIRECT_URL.PATH} />
            <Route element={<ForgotPassword />} exact path="/forgot-password" />
            <Route element={<ResetPassword />} exact path="/reset-password" />
          </Routes>
        </div>
      </BrowserRouter>
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
    </>
  );
}

export default App;
