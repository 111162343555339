/** @fileoverview Login template Component */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Detector } from "react-detect-offline";

import configData from "../config";
import { handleEnterKeyDown } from "../utils";

import BackgroundTemplate from "./BackgroundTemplate";

/**
 * Renders the login template within a background video container.
 *
 * This component displays a login form for users to enter their company name, employee number, and password.
 * The component also dynamically adjusts the title display based on screen size for better responsiveness.
 * @param {object} props The component props.
 * @param {Function} props.setPassword A function to update the user's password state.
 * @param {Function} props.setEmployeeNumber A function to update the user's employee number state.
 * @param {Function} props.setOrgname A function to update the user's company name state.
 * @param {Function} props.handleSubmit A function to handle the form submission for login.
 * @param {string} props.orgname The user's company name (state variable).
 * @param {string} props.password The user's password (state variable).
 * @param {string} props.employeeNumber The user's employee number (state variable).
 * @return {React.JSX} The rendered login template component.
 */
function LoginTemplate({
  setPassword,
  setEmployeeNumber,
  setOrgname,
  handleSubmit,
  orgname,
  password,
  employeeNumber,
}) {
  const [titleType, setTitleType] = useState(true);

  useEffect(() => {
    const updateTitleBasedOnScreenWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1370) {
        setTitleType(false);
      } else {
        setTitleType(true);
      }
    };
    updateTitleBasedOnScreenWidth();
    window.addEventListener("resize", updateTitleBasedOnScreenWidth);
    return () => window.removeEventListener("resize", updateTitleBasedOnScreenWidth);
  }, []);

  return (
    <BackgroundTemplate>
      <Card.Header className="display-simple-flex-center login-header-color border-0 fw-bolder fs-3">Login</Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3 ">
                <InputGroup.Text className="justify-content-center label-box">Company</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setOrgname(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder={titleType ? "Company code" : "Comp. Code"}
                  required
                  value={orgname}
                />
              </InputGroup>
            </Col>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text className="label-box justify-content-center">
                  {titleType ? "Employee Number" : "Emp. No."}
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => setEmployeeNumber(e.target.value.replace(/\D/g, ""))}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder={titleType ? "Employee number" : "Emp. No."}
                  required
                  value={employeeNumber}
                />
              </InputGroup>
            </Col>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text className="label-box justify-content-center">Password</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder="Password"
                  required
                  type="password"
                  value={password}
                />
              </InputGroup>
            </Col>
            <Col className="display-simple-flex-center p-1" lg={6} md={6} xs={12} xxl={6}>
              <Detector
                polling={{ url: configData.LOGIN_BACKEND_URL, timeout: 30000 }}
                render={({ online }) => (
                  <>
                    {!online && <p>No internet connection detected. Please connect to the internet and try again.</p>}
                    <Button
                      className="login-button me-2 mb-1"
                      disabled={!online}
                      onClick={handleSubmit}
                      type="submit"
                      variant="success"
                    >
                      Login
                    </Button>
                  </>
                )}
              />
            </Col>
            <Col className="display-simple-flex-center p-1" lg={6} md={6} xs={12} xxl={6}>
              <Button
                className="login-button me-2"
                onClick={(_event) => window.open("/forgot-password")}
                variant="secondary"
              >
                Forgot Password
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </BackgroundTemplate>
  );
}

LoginTemplate.propTypes = {
  setPassword: PropTypes.func.isRequired,
  setEmployeeNumber: PropTypes.func.isRequired,
  setOrgname: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  orgname: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  employeeNumber: PropTypes.string.isRequired,
};

export default LoginTemplate;
