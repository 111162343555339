/** @fileoverview forget password template Component */
import PropTypes from "prop-types";
import React from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

import { handleEnterKeyDown } from "../utils";

import BackgroundTemplate from "./BackgroundTemplate";

/**
 * Renders the forget password template within a background video container.
 *
 * This component displays a form for users to enter their company name and email address for password reset.
 * @param {object} props The component props.
 * @param {Function} props.handleSubmit A function to handle the form submission for password reset.
 * @param {string} props.email The user's email address (state variable).
 * @param {Function} props.setEmail A function to update the user's email address state.
 * @param {string} props.orgName The user's company name (state variable).
 * @param {Function} props.setOrgName A function to update the user's company name state.
 * @return {React.JSX} The rendered forget password template component.
 */
function ForgetPasswordTemplate({ handleSubmit, email, setEmail, orgName, setOrgName }) {
  return (
    <BackgroundTemplate>
      <Card.Header className="display-simple-flex-center login-header-color border-0 fw-bolder fs-3">
        Reset Password
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3 ">
                <InputGroup.Text className="justify-content-center label-box">Company</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setOrgName(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder="Company"
                  required
                  value={orgName}
                />
              </InputGroup>
            </Col>
            <Col lg={12} md={12} xs={12} xxl={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text className="label-box justify-content-center">Email</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => handleEnterKeyDown(e, handleSubmit)}
                  placeholder="Email"
                  required
                  value={email}
                />
              </InputGroup>
            </Col>
            <Col className="display-simple-flex-center p-1" lg={12} md={12} xs={12} xxl={12}>
              <Button
                className="login-button me-2 mb-1"
                onClick={handleSubmit}
                type="submit"
                variant="success"
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </BackgroundTemplate>
  );
}

ForgetPasswordTemplate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  orgName: PropTypes.string.isRequired,
  setOrgName: PropTypes.func.isRequired,
};

export default ForgetPasswordTemplate;
