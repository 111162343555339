/** @fileoverview Forgot password page */
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import ForgetPasswordTemplate from "./Templates/ForgetPasswordTemplate";
import { handleForgotPasswordSubmit } from "./utils";

/**
 * Forgot password
 * @return {React.JSX} Forgot password page
 */
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [orgName, setOrgName] = useState("");

  useEffect(() => ReactGA.send({ hitType: "pageview", page: "/forgot-password", title: "Forgot Password View" }), []);

  return (
    <ForgetPasswordTemplate
      email={email}
      handleSubmit={(e) => handleForgotPasswordSubmit(e, { email, orgName, setEmail, setOrgName })}
      orgName={orgName}
      setEmail={setEmail}
      setOrgName={setOrgName}
    />
  );
}

export default ForgotPassword;
