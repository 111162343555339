/** @fileoverview Config data */

const configData = {
  LOGIN_BACKEND_URL: process.env.REACT_APP_LOGIN_BACKEND_URL,
  LOGIN_FRONTEND_URL: process.env.REACT_APP_LOGIN_FRONTEND_URL,
  REDIRECT_URL: {
    PORT: process.env.REACT_APP_REDIRECT_URL_PORT,
    PATH: "/login/",
  },
  ENDPOINTS: {
    BACKEND_URL: "/api/customer-backend/",
    LOGIN: "/api/token/",
    FORGOT_PASSWORD: "/api/password_reset/",
    RESET_PASSWORD: "/api/password_reset/confirm/",
  },
  FETCH_TIMEOUT: 300000,
  GOOGLE_ANALYTICS: {
    MEASUREMENT_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    TESTMODE: process.env.REACT_APP_GOOGLE_ANALYTICS_TESTMODE,
  },
};

export default configData;
