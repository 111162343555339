/** @fileoverview Notification popup template for all pages */
import { toast } from "react-toastify";

/**
 * Display a toast notification.
 * @param {string} message The message to be displayed in the toast.
 * @param {"error" | "success" | "info" | "warning"} type The type of toast notification.
 * @param {object} [options] Additional toast options (optional). These can include settings like duration, position,
 * and other customizable properties to enhance the toast notification's appearance and behavior.
 */
const cQINotify = (message, type = "info", options = {}) => {
  switch (type) {
    case "error":
      toast.error(message, options);
      break;
    case "success":
      toast.success(message, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    case "warning":
      toast.warning(message, options);
      break;
    default:
      toast(message, options);
      break;
  }
};

export default cQINotify;
