/** @fileoverview Submit handlers, an email validator, a password validator */

import cQINotify from "./Templates/cQINotify";
import { fetchBackendUrl, login } from "./api";
import configData from "./config";

export const handleLoginSubmit = (event, args) => {
  const { orgname, employeeNumber, password, deviceId, setOrgname, setEmployeeNumber, setPassword } = args;
  event.preventDefault();
  fetchBackendUrl(configData.LOGIN_BACKEND_URL, configData.ENDPOINTS.BACKEND_URL, orgname.toUpperCase())
    .then((resJson) => {
      login(resJson["backend_url"], configData.ENDPOINTS.LOGIN, {
        orgname: orgname,
        employee_number: employeeNumber,
        password: password,
        device_id: deviceId,
      })
        .then((resJson) => {
          setOrgname("");
          setEmployeeNumber("");
          setPassword("");
          const redirectUrl = new URL(resJson["base_url"]);
          redirectUrl.port = configData.REDIRECT_URL.PORT;
          redirectUrl.pathname = configData.REDIRECT_URL.PATH;
          redirectUrl.searchParams.set("token", resJson["access"]);
          redirectUrl.searchParams.set("refresh", resJson["refresh"]);
          redirectUrl.searchParams.set("base_url", resJson["base_url"]);
          redirectUrl.searchParams.set("is_first_login", resJson["last_login"] ? "false" : "true");
          window.location.href = redirectUrl;
        })
        .catch((_error) => {
          cQINotify("The company name, employee number or password you entered is incorrect.", "error");
        });
    })
    .catch((_error) => {
      cQINotify("Customer Not Found/Available", "error");
    });
};

export const handleForgotPasswordSubmit = async (event, args) => {
  const { email, orgName, setEmail, setOrgName } = args;
  event.preventDefault();

  if (!validateEmail(email)) {
    cQINotify("Invalid email domain", "error");
    return;
  }

  fetchBackendUrl(configData.LOGIN_BACKEND_URL, configData.ENDPOINTS.BACKEND_URL, orgName.toUpperCase())
    .then((resJson) => {
      login(resJson["backend_url"], configData.ENDPOINTS.FORGOT_PASSWORD, { orgname: orgName, email: email })
        .then((_resJson) => {
          setEmail("");
          setOrgName("");
          cQINotify("User and Customer available", "success");
          cQINotify("Please check the email.", "info");
        })
        .catch((_error) => {
          cQINotify("The company name or email you entered is incorrect.", "error");
        });
    })
    .catch((_error) => {
      cQINotify("Customer Not Found/Available", "error");
    });
};

export const handleResetPasswordSubmit = async (event, args) => {
  const {
    password,
    confirmPassword,
    token,
    orgName,
    setPassword,
    setConfirmPassword,
    navigate,
    setConfirmPassowordMatch,
  } = args;

  event.preventDefault();

  if (password !== confirmPassword) {
    cQINotify("Both New and Confirm password must match", "error");
    setConfirmPassowordMatch(false);
    return;
  }

  fetchBackendUrl(configData.LOGIN_BACKEND_URL, configData.ENDPOINTS.BACKEND_URL, orgName.toUpperCase()).then(
    (resJson) => {
      login(resJson["backend_url"], configData.ENDPOINTS.RESET_PASSWORD, {
        orgname: orgName,
        token: token,
        password: password,
      }).then((_resJson) => {
        setPassword("");
        setConfirmPassword("");
        navigate("/login", { state: { message: "Your password has been reset successfully." } });
      });
    }
  );
};

export const handleEnterKeyDown = (event, handleSubmit) => {
  if (event.key === "Enter") {
    handleSubmit(event);
  }
};

export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const validateNewPasswordRequirements = (
  password,
  setPasswordLength,
  setCapitalChars,
  setSmallChars,
  setNumberChars,
  setSpecialChars
) => {
  const hasMinLength = password.length >= 8;
  const hasCapitalLetter = /[A-Z]/.test(password);
  const hasLowercaseLetter = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);

  setPasswordLength(hasMinLength);
  setCapitalChars(hasCapitalLetter);
  setSmallChars(hasLowercaseLetter);
  setNumberChars(hasNumber);
  setSpecialChars(hasSpecialChar);

  return hasMinLength && hasCapitalLetter && hasLowercaseLetter && hasNumber && hasSpecialChar;
};
