/** @fileoverview API call functions */
import configData from "./config";

/**
 * Fetch with timeout
 * @param {string} resource URL
 * @param {object=} options fetch options
 * @return {Promise<Response>} fetch response
 */
async function fetchWithTimeout(resource, options = {}) {
  const { timeout = configData.FETCH_TIMEOUT } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, { ...options, signal: controller.signal });
  clearTimeout(id);
  return response;
}

/**
 * Login function
 * @param {string=} backendUrl URL of the backend
 * @param {string=} endpoint API endpoint
 * @param {object=} data API data
 * @return {Promise<*>} API response
 */
async function login(backendUrl = "", endpoint = "", data = {}) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json", accept: "application/json" },
    body: JSON.stringify(data),
  };

  const response = await fetchWithTimeout(backendUrl + endpoint, options);

  const responseJson = await response.json();
  if (!response.ok) {
    if ("error" in responseJson) {
      throw new Error(responseJson["error"]);
    } else {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  }
  return responseJson;
}

/**
 * Fetch the backend URL
 * @param {string=} backendUrl URL of the backend
 * @param {string=} endpoint API endpoint
 * @param {string=} customer Customer name
 * @return {Promise<*>} API response
 */
async function fetchBackendUrl(backendUrl = "", endpoint = "", customer = "") {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json", accept: "application/json" },
  };

  const encodedCustomer = encodeURIComponent(customer);
  const response = await fetchWithTimeout(backendUrl + endpoint + "?customer=" + encodedCustomer,options);
  const responseJson = await response.json();
  if (!response.ok) {
    if ("error" in responseJson) {
      throw new Error(responseJson["error"]);
    } else if ("detail" in responseJson) {
      throw new Error(responseJson["detail"]);
    } else {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  }

  return responseJson;
}

export { login, fetchBackendUrl };
